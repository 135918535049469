.industries {
    padding-top: 50px;
    padding-bottom: 70px;
    background: #eef3ff;
    /* border-bottom: 5px solid #fff; */
}
.industries .box {
    /* margin-top: 40px; */
}
.industries ul li {
    width: 25%;
    display: inline-block;
    text-align: center;
    padding: 40px 0 20px;
    transition: 0.5s all;
}
.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}
.border-right {
    border-right: 1px solid #e5e5e5;
}
.industries ul li a {
    display: block;
    text-decoration: none; /* Remove the underline */
}
a {
    color: rgb(32, 75, 145);
}
.full-width-image-industries {
    object-fit: contain;
    transition: all .5s ease-in-out;
  
}
.full-width-image-industries:hover {
    filter: grayscale(0);
    background-color: white;
}

.blogsection {
    width: 100%;
    height: 100%;
    background-position: center;
    padding-top: 80px;
    padding-bottom: 60px;
}
.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (max-width: 776px) {
    .industries ul li {
        width: 100%; 
    }
}

