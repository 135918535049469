.productSectionColor {
    background-color: #f2f0f9;

}

.card-custom-product {
    padding: 5px;
    background-color: white;
    border-radius: 1rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 1.5rem;
    -ms-border-radius: 1.5rem;
    -o-border-radius: 1.5rem;
    background-color: white;
    border: none;
}

.img-product {
    /* display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    max-height: 300px;
    min-height: 250px;
    height: 300px;
    width: 100%;

        /* display: flex;
    justify-content: center;
    align-items: flex-start; */
    height: 90%;
    width: 15%;
    margin: 12px;
    object-fit: contain;

}

.landingPageImage img {
    height: 100%;
    object-fit: cover;
    border-radius: 7px;
}
.brand-fluid {
    margin: 20px auto 60px;
}
.brand-fluid .row {
    position: relative;
    margin-right: 50px;
    margin-left: 50px;
}
.brand-fluid h2 {
    color: black;
    font-weight: 500;
    font-family: Montserrat;
    font-size: 32px;
}
.brand-fluid p {
    text-transform: uppercase;
    color: black;
    font-weight: 300;
    font-family: 'Poppins';
    letter-spacing: 1px;
    /* font-size: 20px; */
    margin-top: 20px;
    /* margin-bottom: 60px; */
    position: relative;
    display: inline-block;
}
.brand-fluid .row {
    position: relative;
    margin-right: 50px;
    margin-left: 50px;
}
.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}
.brand_flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.border-right {
    border-right: 1px solid #e5e5e5;
}


.industriesp {
    padding-top: 50px;
    /* padding-bottom: 100px; */
    background: white;
    border-bottom: 5px solid #fff;
}
.industriesp .box {
    margin-top: 10px;
}
.industriesp ul li {
    width: 25%;
    display: inline-block;
    text-align: center;
    /* padding: 55px 0 25px; */
    transition: 0.5s all;
}
.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}
.border-right {
    border-right: 1px solid #e5e5e5;
}
.industriesp ul li a {
    display: block;
    text-decoration: none; /* Remove the underline */
}
a {
    color: rgb(32, 75, 145);
}
/* .full-width-image-industries {
    object-fit: contain;
    transition: all .5s ease-in-out;
    filter: grayscale(1);
  
}
.full-width-image-industries:hover {
    filter: grayscale(0);
    background-color: transparent;
} */
.full-width-image-industriesp {
    object-fit: contain;
    transition: all .5s ease-in-out;
    /* filter: grayscale(1); */
    filter: grayscale(0);
  
}
.full-width-image-industriesp:hover {
    filter: grayscale(0);

}
.blogsection {
    width: 100%;
    height: 100%;
    background-position: center;
    padding-top: 80px;
    padding-bottom: 80px;
}
.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (max-width: 776px) {
    .industries ul li {
        width: 100%; 
    }
}

.product-col{
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-col a{
    display: flex;
    justify-content: center;
    align-items: center;
}