.bannerHeight {
  /* background-image: url("./../../../Assets/Images/18246003_v915-wit-001.jpg"); */
  background-image: url("./../../../Assets/Images/home_bg.jpg");
  /* background-image: url("./../../../Assets/Images/16351572_rm209-adj-20.jpg"); */
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
  /* z-index: 1; */

  color: #ffffff;
  transform: rotate(180deg);
  /* filter: brightness(.8); */
    /* -webkit-filter: brightness(.8); */
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&family=Quintessential&display=swap");

.taglineText {
  /* font-family: 'Quintessential', serif; */
  font-size: clamp(35px, 4.1666vw, 55px);
  font-weight: bolder;
  background: #0b4141;
  text-decoration: none;
  text-transform: none;
  /* color: transparent; */
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

#myVideo {
  position: absolute;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  filter: brightness(0.6);
  -webkit-filter: brightness(0.6);
}

.headerText {
  flex-direction: column;
  /* margin-left: 10rem; */
}

.bg-text-color {
  color: #0b4141;
}

.headerText .Typewriter {
  font-size: 4rem;
  font-weight: 700;
  line-height: 5rem;
}

.button-transparent {
  background-color: #2980b9;
  color: white;
  font-weight: 700;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
}

.button-transparent:hover {
  background-color: #2980b9ad;
  color: #191c51;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.bannerRow {
  height: 100vh;
}

.positionAbsolute {
  position: absolute;
  width: 100%;
  bottom: 5rem;
}

.boxcontainer {
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
