.ArticleCardContainer {
  height: auto;
  width: 80%;
}
.ArticleCardImageContainer {
  height: 300px;
  width: 100%;
}
.ArticleCardImage {
  max-height: 100%;
  max-width: 100%;
}
.KeywordContainer {
  width: auto;
  border-radius: 15px;
  background-color: rgb(114, 221, 226);
  margin: 10px;
  padding: 8px;
  font-weight: 500;
}
.ArticleDetailsContainer {
  width: 100%;
}
