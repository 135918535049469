.customNav {
    display: flex;
    justify-content: end;
    align-items: end;
    width: 100%;
    color: white;
}

.nav-link {
    color: black;
    font-weight: 600;
}

.navbar-brand {
    color: black;
    font-weight: 600;
}

.navbar-custom {
    /* background-color: white;
    color: black;
    box-shadow: rgba(149, 157, 165, 0.2); */
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.nav-link {
    cursor: pointer;
}

.Custom-card-design {
    border-radius: 1.5rem;
    -webkit-border-radius: 1.5rem;
    -moz-border-radius: 1.5rem;
    -ms-border-radius: 1.5rem;
    -o-border-radius: 1.5rem;
    box-shadow: 0 0.1875rem 0.4375rem 0 rgba(0, 0, 0, .13), 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, .11);
    color: #371b17;
    height: 100%;
}

.sticky {
    position: fixed;
    top: 1rem;
    right: 0;
    /* padding: 0; */
    background-color: unset;
    width: 100%;
    z-index: 99;
    transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 688px) {
    .headerText .Typewriter {
        font-size: 2rem;
        font-weight: 700;
        line-height: 3rem;
    }

    .bannerText {
        margin-top: 8rem !important;
    }

    .headerText {
        margin-left: 0rem;
    }

    .boxcontainer {
        height: auto;
        width: 95%;
    }

    .height100vh {
        height: 100%;
    }

    .customNav {
        display: flex;
        justify-content: start;
        align-items: start;
        width: 100%;
        color: white;
    }

    .customProduct {
        margin-top: 2rem;
    }


  /* Override button color */
.dropdown-toggle.dropdown-button {
    color: black !important; /* Set the text color */
    background-color: transparent !important; /* Set the background color */
    border-color: transparent !important; /* Set the border color */
}

/* Override hover color */
.dropdown-toggle.dropdown-button:hover {
    background-color: transparent !important; /* Set the background color on hover */
    color: black !important; /* Set the text color on hover */
}

    
}