@import url(https://db.onlinewebfonts.com/c/ee0b916b2e7c8fef46ce318b6f5e8d65?family=DIN+Neuzeit+Grotesk+W01+Bold);
.light-grey-section {
    background-color: #f0eef2;
}
  
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 9rem;
    /* height: 100%; */
    object-fit: contain;
  }
  .swiper-wrapper {
    display: flex;
    align-items: center;
}
/* .outer-container {
    position: relative;
    float: left;
    width: 100%;
}
.inner-container {
    display: flex;          
    flex-wrap: nowrap;      
    justify-content: center; 
    align-items: center;    
  }
  
.light-grey-section {
    background-color: #f0eef2;
}

.trusted-logos-row {
    display: flex;
    justify-content: space-around;
  }
  
  .trusted-logo {
    text-align: center;
  }
  .center-container {
    text-align: center;
    word-spacing: -0.35rem;
}
.vertical-half-padding-top {
    padding-top: 2rem;
} */
.full-width-image {
    object-fit: contain;
    /* transition: all .5s ease-in-out;
    filter: grayscale(1); */
}
.full-width-image:hover {
    filter: grayscale(0);
}
/* .font-design{
    font-family: 'NeuzeitGroLig W01 Regular',Helvetica,Arial,sans-serif;
    color: #5f5d61;
} */
.font-design {
    font-family: 'NeuzeitGro W01 Bold','Arial Black',Helvetica,Arial,sans-serif;
    color: #5f5d61;
  }