.benifitBox {
    height: 100%;
    width: 90%;
    border-radius: 15px;
    /* margin-bottom: 20px; */
}

.benifit-section {
    background: linear-gradient(0deg, rgba(141, 166, 219, 1) 0%, rgba(255, 255, 255, 1) 100%);
    height: 100%;
}

.benifitBox h2 {
    color: #17c4c4;
}