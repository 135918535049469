






















@import url('https://fonts.googleapis.com/css?family=Poppins:400,700,900');
.client-review {
    border-radius: 1.5rem;
    -webkit-border-radius: 1.5rem;
    -moz-border-radius: 1.5rem;
    -ms-border-radius: 1.5rem;
    -o-border-radius: 1.5rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.img-review {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    /* max-height: 300px; */
    /* min-height: 250px; */
    /* height: 300px; */
    /* width: 99%; */
    object-fit: cover;


}
.image-position{
    display: flex;
    justify-content: end;
    position: absolute;
    right: 40px;
    margin-bottom: 20px;
}
.swiper-div{

   padding-left: 15px;
   padding-right: 15px;
}
.card-custom-review {
    display: block;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 20px;
    background-color: white;
    border-radius: 2rem;
    /* -webkit-border-radius: 0.3rem;
    -moz-border-radius: 1.5rem;
    -ms-border-radius: 1.5rem;
    -o-border-radius: 1.5rem; */
    background-color: white;
    border: none;
    width: 829px;
}
.reveiw-thought{
    color: rgba(74,78,86,1);
    font-size: 17px;
    clear: both;
    display: block;
    margin-bottom: 1.5rem;
    position: relative;
    text-align: justify;
    margin-right: 25px;
    font-family: 'Poppins', sans-serif;
    font-weight: 900;
    margin-top: 5px;
}
.revierwer-name-color{

    /* border-bottom: 2px solid #555; */
    clear: both;
    display: block;
    font-size: 18px;
    font-weight: 300;
    color: rgba(24,76,161,1);
    position: relative;
    /* text-decoration: underline 1px; */
    
    font-family: "Montserrat",sans-serif;

  
}
.custom-border-bottom{
    /* border-bottom:  solid #555; */

   font-weight: bold;
    width: 40px;
    color: rgb(13, 17, 24);
    
}
