@import url(https://db.onlinewebfonts.com/c/ee0b916b2e7c8fef46ce318b6f5e8d65?family=DIN+Neuzeit+Grotesk+W01+Bold);

.footer-container {
  background: #002448;
  color: white;
}
.footer-font {
  text-decoration: none;
  /* color: #6ae0ad; */
  color: #5f5d61;
  font-family: 'NeuzeitGro W01','Arial Black',Helvetica,Arial,sans-serif;
  /* font-weight: 400; */
  font-size: 15px;
  line-height: 25px;
}

font-design {
  font-family: 'NeuzeitGro W01 Bold','Arial Black',Helvetica,Arial,sans-serif;
  color: #5f5d61;
}
.font-size-for-footer {
  text-decoration: none;
  color: #ffffff;
  font-family: geograph-regular;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
}
.footerContainer {
  background-color: #160a28;
}
