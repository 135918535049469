@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');


.background-white-color {
    background-color: white;
}

.cardText {
    /* font-family: Ghawar-Light, Helvetica, Arial, sans-serif; */
    /* font-size: .875rem;
    line-height: 1.375rem;
    letter-spacing: .0075rem;
    padding-bottom: 0.625rem;
    color: #676a6e;
    margin: 0;
    font-family: 'Poppins', sans-serif; */

    font-size: 12px;
    color: #7f7f7f;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 10px;
    /* text-transform: lowercase; */
}

.testCss h1 {
    color: yellow !important;
}

/* .btn-see-more {
    border: 2px solid #0b4140;
    padding: 10px;
    width: 20%;
    color: #0b4140;
    font-weight: bold;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
} */

/* .btn-see-more:hover {
    border: 2px solid #0b4140;
    color: white;
    background-color: #0b4140;
    padding: 10px;
    width: 20%;

} */

.card-title {
    /* font-family: ManifaPro2-Light, Frutiger, Muna, Helvetica, Arial, sans-serif;
    font-size: 1.5rem;
    line-height: 2.125rem;
    letter-spacing: .014375rem;
    padding-bottom: 0.625rem;
    margin: 0; */
    font-size: 16px;
    color: #000;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    /* text-transform: capitalize; */
   

}

.full-width-image-services {
    object-fit: contain;
    transition: transform 0.5s ease-in-out; /* Add a transition for the transform property */
}

.full-width-image-services:hover {
    filter: grayscale(0);
    background-color: white;
    transform: scale(0.9); /* Zoom out on hover */
}

  
  /* CSS */
  .btn-see-more {
    margin: 10px;
    padding: 15px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white !important;
    border-radius: 10px;
    display: block;
    border: none!important;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #4f19f0;
    background-image: linear-gradient(to right, rgb(82, 120, 158), rgb(21, 86, 96));
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .btn-see-more:hover {
    background-position: right center;
    color: #fdfbfb !important;
    text-decoration: none;
  }
  
  .btn-see-more:active {
    transform: scale(0.95);
  }