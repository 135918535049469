.bannerImgForCareer {
  /* background-image: url('../../../Assets/Images/18246003_v915-wit-001.jpg'); */
  background-image: url("./../../../Assets/Images/home_bg.jpg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
  /* z-index: 1; */
  color: #0b4141;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.careerText h1 {
  margin-top: 70px;
  font-size: 5rem;
  color: #336264;
  /* font-family: Montserrat;
    font-weight: 500 ;
    font-size: 36px; */
}

.careerTextP {
  font-size: 1.7rem;
  color: #336264;
  font-weight: 500;
  /* margin-left: 10px; */
}

.bannerImageForCareer img {
  border-radius: 1.5rem;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  -ms-border-radius: 1.5rem;
  -o-border-radius: 1.5rem;
}

/* @media only screen and (min-width: 376px) {
    .mobile-margin-top-md-screen {
        margin-top: 220px;
      }
} */
