.main-section-color {
    background: #faf9fd;
}

.height100vh {
    height: 100vh;
}

.gradientColor {
    /* background: linear-gradient(to right, #8CA6DB, #B993D6); */
    background: linear-gradient(to right, rgb(44, 62, 80), rgb(76, 161, 175));
}