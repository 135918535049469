@import url('https://fonts.googleapis.com/css?family=Poppins:400,700,900');
.card-custom {
    /* padding: 10px; */
    padding-right: 10px;
    background-color: white;
    border-radius: 1.5rem;
    -webkit-border-radius: 1.5rem;
    -moz-border-radius: 1.5rem;
    -ms-border-radius: 1.5rem;
    -o-border-radius: 1.5rem;
    /* background-color: #e9e6f5; */
    background: #b993d6;
    background: -webkit-linear-gradient(to right, #8ca6db, #b993d6);
    background: linear-gradient(to right, #8ca6db, #b993d6);
    /* color: white; */
    color: rgb(10, 9, 9);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    overflow: hidden;
  }
  
  .img-div img {
    border-radius: 1.5rem 0rem 0rem 1.5rem;
    -webkit-border-radius: 1.5rem 0rem 0rem 1.5rem;
    -moz-border-radius: 1.5rem 0rem 0rem 1.5rem;
    -ms-border-radius: 1.5rem 0rem 0rem 1.5rem;
    -o-border-radius: 1.5rem 0rem 0rem 1.5rem;
    width: 25rem;
    height: 25rem;
    object-fit: contain;
  }
  
  .custom-backgroud {
    /* background: linear-gradient(0deg, rgba(11, 65, 65, 0.8463760504201681) 0%, rgba(251, 251, 251, 1) 100%); */
    /* background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%) */
    /* background: linear-gradient(to right, #83a4d4, #b6fbff); */
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
    /* background: #B993D6; */
    /* background: -webkit-linear-gradient(to right, #8CA6DB, #B993D6); */
    /* background: linear-gradient(to right, #8CA6DB, #B993D6); */
  }
  .imgtext {
    position: absolute;
    top: 42%;
    left: 22%;
    z-index: 9999;
    color: white;
  }
  .img-div {
    position: relative;
  }
  .text-font {
    font-size: 18px;
    text-align: justify;
    justify-content: center;
    font-family: 'poppins-bold', sans-serif !important;
  }
  
  .firstimage {
    position: absolute;
    -webkit-animation-name: firstanimation;
    -webkit-animation-duration: 10s;
    animation-name: firstanimation;
    animation-duration: 10s;
    animation-delay: 2s;
    animation-iteration-count: infinite;
    object-fit: contain;
  }
  
  .image {
    width: 40%;
    height: auto;
  }
  .image-anim {
    width: 80px;
    height: 80px;
    box-shadow: 4px 8px 13px 0 #e0e0e09e;
    border-radius: 50%;
    object-fit: contain;
    padding: 5px;
  }
  
  .image-container {
    margin-bottom: 0;
    text-align: center;
    padding-top: 30%;
    padding-bottom: 30%;
    position: relative;
  }
  .zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
  }
  
  @keyframes zoomIn {
    0% {
      opacity: 0;
      transform: scale(0.4);
    }
  
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes firstanimation {
    0% {
      left: 45%;
      top: 0;
    }
    20% {
      left: 80%;
      top: 35%;
    }
    40% {
      left: 65%;
      top: 80%;
    }
    60% {
      left: 20%;
      top: 80%;
    }
    80% {
      left: 15px;
      top: 35%;
    }
    100% {
      left: 45%;
      top: 0;
    }
  }
  
  .secondimage {
    position: absolute;
    -webkit-animation-name: secondanimation;
    -webkit-animation-duration: 10s;
    animation-name: secondanimation;
    animation-duration: 10s;
    animation-delay: 2s;
    animation-iteration-count: infinite;
  }
  @keyframes secondanimation {
    0% {
      left: 80%;
      top: 35%;
    }
    20% {
      left: 65%;
      top: 80%;
    }
    40% {
      left: 20%;
      top: 80%;
    }
    60% {
      left: 15px;
      top: 35%;
    }
    80% {
      left: 45%;
      top: 0;
    }
    100% {
      left: 80%;
      top: 35%;
    }
  }
  .thirdimage {
    position: absolute;
    -webkit-animation-name: thirdanimation;
    -webkit-animation-duration: 10s;
    animation-name: thirdanimation;
    animation-duration: 10s;
    animation-delay: 2s;
    animation-iteration-count: infinite;
  }
  @keyframes thirdanimation {
    0% {
      left: 65%;
      top: 80%;
    }
    20% {
      left: 20%;
      top: 80%;
    }
    40% {
      left: 15px;
      top: 35%;
    }
    60% {
      left: 45%;
      top: 0;
    }
    80% {
      left: 80%;
      top: 35%;
    }
    100% {
      left: 65%;
      top: 80%;
    }
  }
  
  .fourthimage {
    position: absolute;
    -webkit-animation-name: fourthanimation;
    -webkit-animation-duration: 10s;
    animation-name: fourthanimation;
    animation-duration: 10s;
    animation-delay: 2s;
    animation-iteration-count: infinite;
  }
  @keyframes fourthanimation {
    0% {
      left: 20%;
      top: 80%;
    }
    20% {
      left: 15px;
      top: 35%;
    }
    40% {
      left: 45%;
      top: 0;
    }
    60% {
      left: 80%;
      top: 35%;
    }
    80% {
      left: 65%;
      top: 80%;
    }
    100% {
      left: 20%;
      top: 80%;
    }
  }
  .fivethimage {
    position: absolute;
    -webkit-animation-name: fivethanimation;
    -webkit-animation-duration: 10s;
    animation-name: fivethanimation;
    animation-duration: 10s;
    animation-delay: 2s;
    animation-iteration-count: infinite;
  }
  @keyframes fivethanimation {
    0% {
      left: 15px;
      top: 35%;
    }
    20% {
      left: 45%;
      top: 0;
    }
    40% {
      left: 80%;
      top: 35%;
    }
    60% {
      left: 65%;
      top: 80%;
    }
    80% {
      left: 20%;
      top: 80%;
    }
    100% {
      left: 15px;
      top: 35%;
    }
  }