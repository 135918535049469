@media only screen and (max-width: 850px){
  .contactUsButton{
    display: flex;
    justify-content: center;
  }

  .bannercarrer{

    justify-content: center;
    height: 100%;
  }
  .logo-center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .mobile-border-bottom {
    border-bottom: 1px solid #e5e5e5;
   
}
.mobile-border-right {
  border-right: 1px solid #e5e5e5;
 
}

.mobile-border-left {
  border-left: 1px solid #e5e5e5;
}



}

/* mobile devices */
.addressHrMobile{
  display: none;
    }
    .addressView{
      display: none !important;
    }

@media only screen and (max-width: 688px) {
  .imageBox {
    height: 146px !important;
    width: 150px !important;
  }
  .addressView{
    display: block !important;
  }
  .foolterl-ul ul li{
    list-style: none;
  }

  .foolterl-ul ul {
    text-align: center;
    padding-left: 0;
  }
  .footerAddress{
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .desktopView{
    display: none !important;
  }
  .addressHrMobile{
display: block;
margin-top: 10px;
  }
  .addressClassFooter {
    display: flex;
    justify-content: center;
  }
  .linksHeader {
    text-align: center;
  }
  .technologyTab {
    justify-content: start !important;
  }
  .technologyTab h4 {
    font-size: 10px;
  }
  .brand-fluid .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .full-width-image-industriesp {
    object-fit: contain;
    transition: all 0.5s ease-in-out;
    filter: grayscale(0) !important;
    margin-top: 20px;
  }
  .footer-container {
    height: 100% !important;
  }

  .btn-see-more {
    width: 100% !important;
  }

  .contactUsButton {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .location {
    width: 100%;
    height: 300px;
  }

  .custom-ui {
    width: 100vw;
  }

  .button {
    width: 7rem;
    padding: 5px !important;
    margin-bottom: 22px;
  }

  .bannerTextAlignment {
    text-align: center;
  }

  .text-justify {
    text-align: justify !important;
    text-justify: inter-word !important;
  }

  .content-center {
    justify-content: center !important;
  }

  .col-reverse {
    flex-direction: column-reverse;
  }

  .technology-section {
    background: linear-gradient(
      0deg,
      rgba(141, 166, 219, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    height: 100% !important;
  }

  .careerText {
    margin-top: 10rem;
  }

  .bannerImgForCareer {
    height: 100% !important;
    padding-bottom: 10px;
  }

  /* .bannerHeight {
    height: 100% !important;
    padding-bottom: 10px;
  } */

  .careerText h1 {
    font-size: 4rem;
  }

  .img-article {
    width: 15rem;
  }
  .client-img-center{
    display: flex;
    justify-content: center;
    margin-right: 15px;
   margin-bottom: 5px;
   
  }
  .logo-center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .mobile-border-bottom {
    border-bottom: 1px solid #e5e5e5;
   
}
.mobile-border-right {
  border-right: 1px solid #e5e5e5;
 
}

.mobile-border-left {
  border-left: 1px solid #e5e5e5;
}

.mobile-margin-top {
  margin-top: 100px;
}
}


@media only screen and (min-width: 376px) and (max-width: 700px){
  /* .mobile-margin-top-md-screen {
      margin-top: 1rem;
    } */
}
/* @media only screen and (min-width: 701px) and (max-width: 900px){
  .mobile-margin-top-md-screen {
      margin-top: 30rem;
    }
} */